.brochure-container {
  text-align: center;
  padding: 15px;
  background-color: #f9f9f9;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brochure-title {
  font-size: 2rem;
  color: #222;
  margin-bottom: 10px;
}

.brochure-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
  max-width: 600px;
}

.brochure-button {
  background-color: #3498db;
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: 0.3s;
}

.brochure-button:hover {
  background-color: #217dbb;
}
