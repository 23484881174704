/* Global Styles */
.esisa-cube-container {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.esisa-cube-header {
  background-color: #4cb6ea;
  text-align: center;
  padding: 30px;
  color: white;
}

.esisa-cube-header h1 {
  font-size: 2.5em;
  margin: 0;
}

.esisa-cube-header p {
  font-size: 1.2em;
  margin: 10px 0 0;
}

/* Introduction Section */
.esisa-cube-intro {
  display: flex;
  justify-content: space-around;
}

.esisa-cube-intro-text {
  width: 48%;
  font-size: 2.1em;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.esisa-cube-intro-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  animation: floatLogo 5s ease-in-out infinite;

}
@keyframes floatLogo {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}


/* Features Section */
.esisa-cube-features {
  background-color: #f9f9f9;
  padding: 50px 0;
}

.esisa-cube-features h2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 40px;
}

.esisa-cube-feature-card {
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 30%;
  display: inline-block;
  vertical-align: top;
  height: 30rem;
}

.esisa-cube-feature-card h3 {
  font-size: 1.5em;
  margin: 15px 0;
}

.esisa-cube-feature-card p {
  font-size: 1.1em;
}

/* Testimonials Section */
.esisa-cube-testimonials {
  background-color: #eef5f9;
  padding: 40px 0;
}

.esisa-cube-testimonial {
  text-align: center;
  font-style: italic;
  margin: 30px 0;
}

.esisa-cube-testimonial blockquote {
  font-size: 1.2em;
  margin-bottom: 10px;
}

/* Contact Section */
.esisa-cube-contact {
  background-color: #4cb6ea;
  color: white;
  text-align: center;
  padding: 50px 20px;
  border-radius: 20px;
}

.esisa-cube-contact h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.esisa-cube-contact p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.esisa-cube-cta-button {
  background-color: #fff;
  color: #4cb6ea;
  font-size: 1.2em;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.esisa-cube-cta-button:hover {
  background-color: #e6f3f9;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .esisa-cube-intro {
    flex-direction: column;
    gap: 30px;
  }

  .esisa-cube-intro-text,
  .esisa-cube-intro-image {
    width: 100%;
  }

  .esisa-cube-feature-card {
    width: 85%;
    margin-bottom: 20px;
  }
}
