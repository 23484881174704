@media screen and (max-width: 650px) {
    .image {
      height: 20rem;
    }
  }
  
  .slick-arrow .slick-prev {
    opacity: 0;
  }
  
  .h1hackaton {
    font-weight: bold;
    font-size: 1rem;
    color: white;
    padding: 1.3rem;
    opacity: 1;
  }
  
  .divhackaton {
    position: absolute;
    bottom: 80%; /* Centre verticalement */
    left: 50%; /* Centre horizontalement */
    transform: translate(-50%, 50%); /* Ajuste pour un centrage parfait */
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    background: #4cb6ea;
    width: auto; /* Évite que le bouton prenne toute la largeur */
    z-index: 10; /* Assure que le bouton reste visible */
  }
  
  .divmamann1,
  .divmamann2 {
    position: relative; /* Permet le positionnement absolu des enfants */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hackatonn {
    position: absolute;
    bottom: 50%; /* Centre verticalement */
    left: 50%; /* Centre horizontalement */
    transform: translate(-50%, 50%); /* Ajuste pour un centrage parfait */
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    background: #4cb6ea;
    width: auto; /* Évite que le bouton prenne toute la largeur */
    z-index: 10; /* Assure que le bouton reste visible */
    scale: 1.1;
  }

 
  
  @media screen and (min-width: 651px) and (max-width: 999px) {
    .image {
      height: 40rem;
      width: 100%;
    }
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1649px) {
    .image {
      height: 61rem;
      width: 100%;

    }
  }
  
  @media screen and (min-width: 1650px) {
    .image {
      height: 95rem;
      width: 100%;

    }
  }
  
  .disabled {
    pointer-events: none; 
    color: #999; 
    cursor: default; 
  }
  
  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
  }
  

  @media screen and (min-width:500px) {
    .hackatonn1 {
      position: absolute;
      bottom: 80%;
      left: 50%;
      transform: translate(-50%, 50%);
      color: white;
      font-weight: bold;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      background: #0d0367;
      width: auto;
      z-index: 10;
      scale: 1.1;
      font-size: 3rem;
      text-align: center;
      box-shadow: -1px -1px 9px 6px #4cb6ea;
      padding: 2rem 5rem;
      opacity: 0.9;
    }
    
  }

  @media screen and (max-width:499px) {
    .hackatonn1 {
      position: absolute;
      bottom: 80%;
      left: 50%;
      transform: translate(-50%, 50%);
      color: white;
      font-weight: bold;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      background: #0d0367;
      width: auto;
      z-index: 10;
      scale: 1.1;
      font-size: 1.5rem;
      text-align: center;
      box-shadow: -1px -1px 9px 6px #4cb6ea;
      padding: 1rem;
      opacity: 0.9;
    }
    
  }

  .hackatonn:hover{
    background-color: #0d0367;
    color: #4cb6ea ;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }


  @media screen and (max-width:499px) {
    .hackatonn {
      position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    background: #4cb6ea;
    width: auto;
    z-index: 10;
    scale: 1.1;
    font-size: 1rem;
    }
    
  }

 
  @media screen and (min-width:500px) {
    .hackatonn {
      position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    background: #4cb6ea;
    width: auto;
    z-index: 10;
    scale: 1.1;
    font-size: 2rem;    padding: 1rem 2rem;

    
    }
    
  }