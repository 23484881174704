.etud-admis-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #4cb6ea, #4c8fea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
 


  @media screen and (max-width: 500px) {
    .etud-admis-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* 3 colonnes */
      grid-template-rows: auto;   /* Lignes auto pour ajuster à la hauteur du contenu */
      gap: 20px; /* Espace entre les éléments */
    }
  }

  .slick-list{
    margin-bottom: 1rem;
  }
  
  @media screen and (min-width:501px) and (max-width: 999px) {
    .etud-admis-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 3 colonnes */
      grid-template-rows: auto;   /* Lignes auto pour ajuster à la hauteur du contenu */
      gap: 20px; /* Espace entre les éléments */
    }
  }
  
  @media screen and (min-width: 1000px) {
    .etud-admis-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 3 colonnes */
      grid-template-rows: repeat(1, auto);   /* 2 lignes */
      gap: 20px; /* Espace entre les éléments */
    }
  }