.esisaC19-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .esisaC19-content {
    max-width: 800px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .esisaC19-title {
    font-size: 24px;
    font-weight: bold;
    color: #4cb6ea;
    margin-bottom: 15px;
  }
  
  .esisaC19-description {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .esisaC19-highlight {
    font-weight: bold;
    color: #d9534f;
  }
  
  .esisaC19-image-placeholder {
    width: 100%;
    height: 200px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 16px;
    border-radius: 8px;
    margin: 20px 0;
  }
  

  .esisaC19-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    background-color: #0056b3;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px;
    transition: background 0.3s ease;
  }
  
  .esisaC19-button:hover {
    background-color: #c7c4c4;
    color:#0056b3 ;
  }
  