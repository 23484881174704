
  /* En-tête des workshops */
  .workshop-heading {
    font-size: 25px;
    color: #1962e5;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  /* Container de workshop */
  .workshop-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-left: -2rem;
    padding: 40px;
  }
  
  /* Carte de workshop */
  .workshop-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .workshop-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  /* Header du workshop */
  .workshop-header {
    padding: 20px;
    background-color: #4cb6ea;
    color: #fff;
    text-align: center;
  }
  
  .workshop-title {
    font-size: 18px;
    margin: 0;
    font-weight: bold;
  }
  
  .workshop-subtitle {
    font-size: 14px;
    margin: 5px 0 0;
    font-style: italic;
  }
  
  /* Description du workshop */
  .workshop-description {
    padding: 20px;
    color: #555;
  }
  
  .workshop-description p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .workshop-objectives {
    list-style-type: none;
    padding-left: 0;
    margin: 20px 0 0;
  }
  
  .workshop-objectives li {
    font-size: 14px;
    margin: 10px 0;
    position: relative;
    padding-left: 20px;
  }
  
  .workshop-objectives li:before {
    content: '✓';
    position: absolute;
    left: 0;
    top: 0;
    color: #1962e5;
    font-size: 18px;
    line-height: 18px;
  }
  
  /* Nos partenaires */
  .NosPartenaires {
    margin-top: 40px;
  }
  