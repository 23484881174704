

/* Ensure the images are responsive and fit within their container */
.partenaires-grid .partenaire-item {
  text-align: center; /* Center the image within the item */
}

.partenaires-grid .partenaire-item img {
  max-width: 50%; /* Image will scale down but not grow larger than its container */
  height: auto; /* Maintain aspect ratio of images */
  display: block; /* Remove extra space below images */
}


@media screen and (min-width:1001px){
  .partenaires-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns, each taking up equal space */
  gap: 60px; /* Space between the items */
  padding: 10px; /* Optional padding around the grid */
}
}

.img11{
  width: 200px;

}

.partenaire-item{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:500px){
  .partenaires-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns, each taking up equal space */
    gap: 50px; /* Space between the items */
    padding: 10px; /* Optional padding around the grid */
  }
}

@media screen and (min-width:500px) and (max-width:1000px){
  .partenaires-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Two columns, each taking up equal space */
    gap: 50px; /* Space between the items */
    padding: 10px; /* Optional padding around the grid */
  }
}
