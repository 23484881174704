.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #051951; /* Couleur de fond */
  z-index: 9999;
}

.preloader-logo {
  position: relative;
  width: 120px; /* Taille du logo */
  height: 120px;
}

.preloader-logo img {
  width: 100%; /* Ajuste la taille de l'image */
  display: block;
  z-index: 1;
}

.loader-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px; /* Diamètre du cercle */
  height: 150px;
  border: 4px solid transparent;
  border-top: 4px solid #4cb6ea; /* Couleur de l'anneau */
  border-radius: 50%;
  animation: spin 1.5s infinite linear;
  transform: translate(-50%, -50%);
}

/* Animation de rotation */
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
