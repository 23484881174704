/* Conteneur principal */
.stage-info {
  background-color: #f4f4f4;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

/* Titres de section */
.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Contenu */
.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Objectifs et rôles */
.objectives, .roles, .evaluation {
  margin-bottom: 20px;
}

.objective, .role, .criteria {
  margin-bottom: 15px;
}

h3 {
  font-size: 3rem;
  color: #555;
  margin-bottom: 10px;
}

h4 {
  font-size: 2rem;
  color: #666;
}

p {
  font-size: 1.5rem;
  color: #444;
  line-height: 1.6;
}

/* Lien */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


.stage-info {
  background-color: #f4f4f4;
  padding :25px 0px;


}