/* Style général */
.privacy-container {
    max-width: 900px;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Titre principal */
  .privacy-title {
    font-size: 28px;
    text-align: center;
    color: #4cb6ea;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Sections */
  .privacy-section {
    margin-bottom: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  /* Titres des sections */
  .privacy-section h2 {
    font-size: 22px;
    color: #3498db;
    border-bottom: 2px solid #3498db;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  /* Paragraphes */
  .privacy-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  