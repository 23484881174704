.president-container {
  display: flex;
  justify-content: center;
  padding: 40px;
  background-color: #f4f4f4;
}

.president-content {
  max-width: 800px;
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.president-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
}

.president-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.president-image {
  max-width: 240px;
  height: auto;
  border: 3px solid #2c3e50;
  border-radius: 8px;
  margin-bottom: 20px;
}

.president-text {
  font-size: 1.1rem;
  font-family: 'Georgia', serif;
  line-height: 1.8;
  text-align: justify;
  color: #333;
}

.president-signature {
  font-weight: bold;
  color: #2c3e50;
  margin-top: 30px;
}

.president-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  text-align: left;
}

.president-list li {
  margin-bottom: 10px;
}

.president-link {
  text-decoration: none;
  color: #888;
}

.president-link:hover {
  color: #2c3e50;
}

.president-email {
  margin-top: 30px;
}

.president-email a {
  text-decoration: none;
  color: #2c3e50;
  font-weight: bold;
}
