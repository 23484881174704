.hackaton-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .hackaton-header {
    text-align: center;
    background-color: #4cb6ea;
    color: white;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
  
  .hackaton-title {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .hackaton-subtitle {
    font-size: 1.2em;
    font-weight: 300;
  }


  
@media screen and (max-width:500px) {

  .hackaton-image-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    background: url('./hackaton.png');
    object-fit: cover;

  }
  
  
}

@media screen and (min-width:501px) {

  .hackaton-image-container {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    margin-bottom: 20px;
    background: url('./hackaton.png');
    object-fit: cover;

  }
  
  
}
  .section-title {
    font-size: 1.5em;
    color: #4cb6ea;
    margin-bottom: 10px;
  }
  
  .hackaton-prizes {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .prize {
    text-align: center;
    font-size: 1.2em;
  }
  
  .hackaton-schedule {
    list-style: none;
    padding-left: 0;
  }
  
  .hackaton-schedule li {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  
  .required-docs {
    list-style: none;
    padding-left: 0;
  }
  
  .required-docs li {
    font-size: 1.1em;
    margin-bottom: 5px;
  }
  
  .registration-link {
    font-size: 1.2em;
    text-decoration: none;
    color: #4cb6ea;
    font-weight: bold;
  }
  
  .registration-link:hover {
    text-decoration: underline;
  }
  
  /* Nouveau style pour le bouton "S'inscrire" */
  .register-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .register-button {
    font-size: 1.2em;
    color: white;
    background-color: #4cb6ea;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s ease;
  }

  .register-button:hover {
    background-color: #0d0367;
    color: #4cb6ea ;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
  
  .hackaton-footer {
    text-align: center;
    font-size: 0.9em;
    color: #777;
    margin-top: 30px;
  }
  