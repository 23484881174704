/* Conteneur principal */
.emplois-section {
  padding: 15px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Conteneur de la page */
.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

/* Titres */
.heading h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

/* Texte d'information */
.info-text {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
}

.email-link {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

/* Image */
.image-container {
  margin-top: 20px;
}

.emploi-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Section des partenaires */
.nos-partenaires {
  margin-top: 40px;
}


.button-container {
  text-align: center;
  margin-top: 20px;
}

.btn-join-alumni {
  background-color: #4cb6ea;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 8px;
  transition:  0.5s ease-in-out;
}

.btn-join-alumni:hover {
  background-color: #21638e;
  color: white;
}
