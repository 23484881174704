.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.header {
  text-align: left;
  margin-bottom: 40px;
}

.header-title {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
}

.introduction, .work-environment, .amphi-theatre, .pedagogical-team, .active-pedagogy, .ambitious-goals, .international-exposure {
  margin-bottom: 60px;
}

.section-title {
  color: #1962e5;
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.gallery-section {
  margin-top: 40px;
}

.gallery-item {
  margin-bottom: 30px;
}

.partner-list {
  list-style: none;
  padding: 0;
}

.partner-list li {
  margin-bottom: 15px;
}

.partner-list a {
  text-decoration: none;
  color: #1962e5;
  font-weight: bold;
}

/* Styles pour les partenaires */
.partners-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0rem;
}

.partner-item {
  flex: 1 1 calc(11.6667% - 1rem); /* 6 images par ligne sur PC */
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .partner-item {
    flex: 1 1 calc(50% - 1rem); /* 2 images par ligne sur mobile */
  }
  
}



  .partner-image {

    max-width: 100%;
    height: auto;
    padding: 1rem;
  
  }

  .partner-image:hover{
    cursor: pointer;
    transition: 1s ease-in-out;
    scale: 1.1;
  }

  @media  (min-width: 440px) and (max-width: 768px) {
    .partners-gallery {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      padding: 2rem;
    }

   
  }

  @media screen and (max-width:950px) {
    .partner-image {
      
      padding: 1rem;
      scale: 0.78;
  }
    
  }
  

