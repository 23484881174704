.actu {
  height: 41rem;
}

@media screen and (max-width: 600px) {
  .actu {
    height: 25rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 980px) {
  .actu {
    height: 40rem;
  }
}

@media screen and (min-width: 981) and (max-width: 1200px) {
  .actu {
    height: 47rem;
  }
}

