.disabled-link {
  pointer-events: none;
  color: gray; /* Style optionnel pour indiquer visuellement que le lien est désactivé */
  text-decoration: none;
  opacity: 0.3;
}


.psousmaster{
  margin: 0 !important;
  padding: 0 !important;
}