/* Style du label contenant la case à cocher */
.consent-label {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #333;
  font-family: 'Arial', sans-serif;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}



/* Effet quand la case est cochée */
.consent-checkbox:checked {
  background-color: #4cb6ea;
  border-color: #4cb6ea;
  box-shadow: 0 0 5px rgba(76, 182, 234, 0.6);
}

/* Ajout d'un effet de coche dans la case à cocher */
.consent-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 6px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Style du texte à côté de la case */
.consent-text {
  font-size: 0.9em;
  color: #555;
  transition: color 0.3s ease;
}

/* Changement de couleur du texte lorsque la case est survolée */
.consent-label:hover .consent-text {
  color: #4cb6ea;
}


.labeldiv{
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 7%;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-spinner2 {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4cb6ea;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
}

.mumspinner{
  display: flex
  ;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      margin-left: 3rem;
}

.btnsubmitform {
  padding: 10px 20px;
  background-color: #4cb6ea;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.inputbacautre{
  border: 1px solid gray !important;
    width: 100%;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 2rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.formcandidate{
  text-align: start;
  display: flex;
  justify-content: space-between;
  gap: 7%;
}

@media screen and (max-width:1000px) {
  .formcandidate{
    text-align: start;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  
}

.formtodo{
  display: flex;
  flex-direction: column;
}

.numberandmail{
  display: flex;

}

.phonetel{
  width: 100% !important;
}

@media screen and (max-width:1000px) {
  .numberandmail{
    display: flex;
    justify-content: space-between;
  }
  
}