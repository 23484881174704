/* 🟢 Conteneur principal */
.esisa-international-container {
  padding: 4rem 2rem;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: 24px;
  color: #fff;
  overflow: hidden;
}

/* 🔵 Header : Titre avec effet lumineux */
.esisa-international-header {
  text-align: center;
  margin-bottom: 4rem;
}

.esisa-international-header h1 {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(90deg, #4cb6ea, #67d8f4);
  -webkit-background-clip: text;
  color: transparent;
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #4cb6ea;
  }
  to {
    text-shadow: 0 0 25px #67d8f4;
  }
}

.esisa-international-header p {
  font-size: 1.2rem;
  opacity: 0.8;
  line-height: 1.8;
}

/* 🟣 Section Contenu */
.esisa-international-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  line-height: 1.8;
  font-size: 1.1rem;
  color: #e3eaf5;
}

/* 🔶 Highlight Card (blocs stylisés) */
.highlight-card {
  background: linear-gradient(145deg, #889ccb, #a3b6e0);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.highlight-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.4);
}

.highlight-card h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #67d8f4;
}

/* 🌟 Section Partenaires */
.esisa-international-partners {
  margin-top: 5rem;
}

.esisa-international-partners h2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
  color: #4cb6ea;
}

/* 📱 Responsive Design */
@media (max-width: 1024px) {
  .esisa-international-header h1 {
    font-size: 2.5rem;
  }

  .highlight-card {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .esisa-international-header h1 {
    font-size: 2rem;
  }

  .esisa-international-container {
    padding: 2rem 1rem;
  }

  .highlight-card {
    padding: 1rem;
  }

  .esisa-international-partners h2 {
    font-size: 2rem;
  }
}
