.esisa-analytica-container {
    text-align: center;
    padding: 50px;
    background-color: #f4f6f9;
    color: #333;
    font-family: 'Arial', sans-serif;
    display: flex;
    gap: 30px;
  }
  .logolabo{
    width: 50%;
  }

  .logolabo {
    width: 50%;
    animation: floatLogo 5s ease-in-out infinite;
  }
  
  @keyframes floatLogo {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @media screen and (max-width: 600px) {
    .esisa-analytica-container {
      flex-direction: column;
    }
    .logolabo {
      width: 100%;
    }
  }
  
  

  @media screen and (max-width:600px) {
    .esisa-analytica-container {
     flex-direction: column;
   
    }
    .logolabo{
      width: 100%;
    }
    
  }
  
  .esisa-analytica-header h1 {
    font-size: 3rem;
    color: #4cb6ea;
    margin-bottom: 20px;
  }
  
  .esisa-analytica-header p {
    font-size: 1.2rem;
    color: #666;
    max-width: 700px;
    margin: 0 auto 30px;
  }


  .esisa-analytica-content p {
    font-size: 1.1rem;
    color: #444;
    max-width: 900px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .visit-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #4cb6ea;
    color: white;
    font-size: 1.1rem;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 30px;
    transition: background-color 0.3s ease;
  }
  
  .visit-button:hover {
    background-color: #39a1c8;
  }
  