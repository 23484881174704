.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5rem;
}

.button-group button {
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  background-color: #f0f0f0;
  margin-right: 10px;
}

.button-group button.active {
  background-color: #4cb6ea;
  color: white;
  font-weight: bold;
}

.labelcheck {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5%;
}

.buttonsendcontact {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  background-color: #007bff;
  transition: background 0.3s;
  color: white;
}

.buttonsendcontact .active {
  background-color: #007bff;
  color: white;
}

.buttonsendcontact:hover  {
  background-color: #007bff;
  color: white;
}

.form label {
  text-align: left;
  font-weight: bold;
  margin: 10px 0 5px;
}

.form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
}

.submit {
  margin-top: 15px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.submit:hover {
  background-color: #0056b3;
}
