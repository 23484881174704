.admis-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .admis-content {
    width: 100%;
    max-width: 1200px;
  }
  
  .admis-heading {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .admis-description {
    margin-bottom: 20px;
  }
  
  .admis-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .admis-table td {
    padding: 10px;
    border: 1px solid #ddd;
    vertical-align: middle;
    text-align: left;
  }
  
  .pdf-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
  
  .admis-table a {
    color: #1962e5;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .admis-table a:hover {
    text-decoration: underline;
  }
  