@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.divchiffre {
  transition: all 0.3s ease-in-out;
  animation: floating 4s infinite ease-in-out;

}

.divchiffre:hover {
  transform: translateY(-20px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
