.logoPartenaire {
  margin-bottom: 0;
  padding-top: 5%;
  margin: auto;
  width: 50%;
}

@media screen and (max-width: 550px) {
  .logoPartenaire {
    width: 17rem;
    height: 12.5rem;
  }
}

.logoPartenaire2 {
  margin-bottom: 0;
  padding-top: 5%;
  margin: auto;
  width: 50%;
}

@media screen and (max-width: 550px) {
  .logoPartenaire2 {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .imglogopartnaire {
    text-align: center;
  }
}
.imglogopartnaire {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .fifty {
    width: 100%;
    text-align: center;
  }
}

.divecole {
  background-color: rgb(248, 248, 248);
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 60rem;
  border-radius: 2px rgb(66, 66, 66);
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divecole2 {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 60rem;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divecole3 {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 60rem;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divecole4 {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 60rem;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 500px) {
  .divecole4 {
    height: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .divecole1 {
    height: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .divecole2 {
    height: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .divecole3 {
    height: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .divecole {
    height: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
