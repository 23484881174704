.recognition-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f4f4f4;
  }
  
  .recognition-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #89c4fe;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .recognition-subtitle {
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    padding: 2rem;
  }
  

  
  .recognition-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    text-align: left;
    max-width: 800px;
  }
  

  .button-container {
    margin-top: 30px;
    text-align: center;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: #4cb6ea;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #389dc3;
  }

  @media screen and (max-width:500px) {
    .strongest{
        color:  #89c4fe;
        font-size: 1.2rem;
       }
       .strongestt{
        color:  #095db1;
        font-size: 1.2rem;
       }
       .recognition-text {
        font-size: 1.1rem;
        font-family: 'Georgia', serif;
        line-height: 1.8;
        text-align: justify;
        color: #333;
        margin-bottom: 15px;
    }
    .recognition-list li {
        margin-bottom: 20px;
        font-size: 1.1rem;
        color: #333;
      }
      
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .esisa-logo {
    width: 150px;
    height: auto;
  }
  

  @media screen and (min-width:501px) {
    .strongest{
        color:  #89c4fe;
        font-size: 2.5rem;
       }

       .strongestt{
        color:  #095db1;
        font-size: 2.5rem;
       }
       .recognition-text {
        font-size: 2rem;
        font-family: 'Georgia', serif;
        line-height: 1.8;
        text-align: justify;
        color: #333;
        margin-bottom: 15px;
    }
    .recognition-list li {
        margin-bottom: 20px;
        font-size: 2rem;
        color: #333;
      }
      
  }
  
 