@media screen and (max-width: 767px) {
  .ptag {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mobileadded {
    text-align: center;
  }
}

.gapax {
  display: flex;
  justify-content: center;
  gap: 15px;
}
