/* Styles généraux */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

/* Titre principal */
.iq-heading {
  text-align: center;
  margin: 40px 0;
}

.iq-heading h4 {
  font-size: 28px;
  color: #1962e5;
  text-transform: uppercase;
}

/* Conteneur principal */
.themes-recherche-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Section du contenu */
.content-wrapper {
  max-width: 900px;
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Texte d'introduction et conclusion */
.introduction,
.conclusion {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
  margin-top: 2rem;
}

/* Grille des thèmes */
.themes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

/* Cartes des thèmes */
.theme-card {
  background: #4cb6ea;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.theme-card a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.theme-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Section partenaires */
.nos-partenaires {
  width: 100%;
  max-width: 900px;
}
