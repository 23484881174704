/* Conteneur principal */
.ccna-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: #f5f7fa;
}

/* Contenu de la formation */
.ccna-content {
  max-width: 900px;
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Titre principal */
.ccna-header h4 {
  font-size: 28px;
  font-weight: bold;
  color: #1962e5;
  text-align: center;
  margin-bottom: 20px;
}

/* Texte descriptif */
.ccna-description p {
  font-size: 16px;
  color: #444;
  line-height: 1.6;
  margin-bottom: 12px;
}

/* Titres des sections */
.ccna-title {
  font-size: 20px;
  color: #1962e5;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

/* Conteneur des cours */
.ccna-curriculum {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

/* Bloc de chaque cours */
.ccna-course {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.ccna-course:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

/* Catégorie du cours */
.course-category {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Liens vers les cours */
.course-link {
  display: inline-block;
  padding: 8px 15px;
  background: #1962e5;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
}

.course-link:hover {
  background: #154db2;
}

/* Responsive */
@media (max-width: 768px) {
  .ccna-container {
    padding: 20px;
  }

  .ccna-content {
    padding: 20px;
  }

  .ccna-title {
    font-size: 18px;
  }

  .course-category {
    font-size: 16px;
  }
}
