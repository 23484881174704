
.iq_logo{
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 1rem;

}

.logoesisaaa{
	width: 100% !important;


}

@media screen and (max-width:500px) {
	.logoreconnu{
		width: 70% !important;
		padding: 1rem;
	}
	
}

@media screen and (min-width:501px) {
	.logoreconnu{
		width: 35% !important;
		padding: 1rem;
	}
	
}

.logoreconnu:hover{
	scale: 1.1;
	transition: 1s ease-in-out;
}
