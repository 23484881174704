

@media screen and (min-width:1000px) {
    .logo {
        width: 10rem;
        height: 10rem;
    }
}

@media screen and (max-width:999px) {
    .logo {
        width: 30%;
        height: 30%;
    }
}

.loading-screen {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-logo {
    width: 150px;
    animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.form3 label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .form3 input,
  .form3 select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  .form3 select,
  .form3 input {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  ::placeholder {
    opacity: 0.7;
    font-size: 0.9rem;
  }
