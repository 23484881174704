/* Style Global */
body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

/* Conteneur Principal */
.admission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

/* Contenu Principal */
.admission-content {
  max-width: 900px;
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-align: center;
}

/* Titre et Sous-Titre */
.admission-header h2 {
  font-size: 32px;
  color: #1962e5;
  margin-bottom: 10px;
}

.admission-subtitle {
  font-size: 18px;
  color: #666;
  font-style: italic;
  margin-bottom: 20px;
}

/* Processus d'Admission */
.admission-process p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Liste Critères */
.admission-criteria {
  list-style: none;
  padding: 0;
  text-align: left;
  margin: 20px auto;
  max-width: 700px;
}

.admission-criteria li {
  background: rgb(76, 182, 234);
  color: white;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.admission-criteria li:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Galerie */
.admission-gallery {
  margin-top: 30px;
}

.admission-image {
  width: 100%;
  max-width: 750px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.admission-image:hover {
  transform: scale(1.05);
}
