.master-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
}

.master-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" fill="%234cb6ea" opacity="0.05"/></svg>');
  opacity: 0.5;
  z-index: 0;
}

.master-header {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  z-index: 1;
}

.master-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #2c3e50;
  margin: 1.5rem 0;
  line-height: 1.2;
  background: linear-gradient(135deg, #4cb6ea 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientFlow 8s ease infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  text-align: center;
}

.master-subtitle {
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 2rem;
  font-weight: 500;
}

.master-content {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.master-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #495057;
  margin-bottom: 3rem;
  text-align: justify;
}

.semester-section {
  margin-bottom: 4rem;
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.semester-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(76, 182, 234, 0.1) 0%, rgba(44, 62, 80, 0.1) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.semester-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.semester-section:hover::before {
  opacity: 1;
}

.semester-title {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.semester-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #4cb6ea, #2c3e50);
  border-radius: 2px;
}

.course-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.course-item {
  padding: 1rem 1.5rem;
  background: #f8f9fa;
  border-radius: 10px;
  color: #495057;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.course-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #4cb6ea;
  transform: scaleY(0);
  transition: transform 0.3s ease;
}

.course-item:hover {
  background: white;
  transform: translateX(5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.course-item:hover::before {
  transform: scaleY(1);
}

.master-image {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 2rem 0;
}

.master-image:hover {
  transform: scale(1.02);
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 3rem;
}

.btn {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.btn:hover::before {
  transform: translateX(100%);
}

.btn-primary {
  background: linear-gradient(135deg, #4cb6ea 0%, #2c3e50 100%);
  border: none;
  color: white;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(76, 182, 234, 0.3);
}

.btn-secondary {
  background: white;
  border: 2px solid #4cb6ea;
  color: #4cb6ea;
}

.btn-secondary:hover {
  background: #4cb6ea;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(76, 182, 234, 0.2);
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .master-container {
    padding: 1rem;
  }

  .master-title {
    font-size: 2rem;
  }

  .master-subtitle {
    font-size: 1.2rem;
  }

  .master-content {
    padding: 2rem;
  }

  .semester-section {
    padding: 1.5rem;
  }

  .semester-title {
    font-size: 1.5rem;
  }

  .course-list {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .btn {
    width: 100%;
    justify-content: center;
  }
}

.recognition-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 77px;
  height: auto;
  z-index: 10;
}

@media screen and (min-width:1000px) {
    .recognition-badge {

        width: 10%;
    
      }
}



@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
