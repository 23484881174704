/* SystemeEnseignement.css */

.systeme-enseignement-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f9f9f9;
}

.content-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  color: #1962e5;
  text-align: left;
}

.content {
  font-family: Arial, sans-serif;
  color: #333;
}

.intro, .cycle-description {
  margin-bottom: 20px;
  line-height: 1.6;
}

.cycle-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.cycle-list li {
  margin-bottom: 10px;
}

.cycle-list a {
  text-decoration: none;
  color: #1962e5;
  font-weight: bold;
}

.cycle-list a:hover {
  text-decoration: underline;
}

.section-title {
  font-size: 1.5rem;
  color: #1962e5;
  margin: 20px 0 10px;
}

/* NosPartenaires.css */

.nos-partenaires {
  display: none; /* Masquer le composant par défaut */
}

/* Afficher le composant lorsque la largeur de l'écran est entre 0px et 1700px */
@media (min-width: 0px) and (max-width: 980px) {
  .nos-partenaires {
    display: block;
  }
}

