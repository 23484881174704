.presse-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .presse-content {
    width: 100%;
    max-width: 1200px;
  }
  
  .presse-heading h4 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .presse-body {
    overflow: auto;
  }
  
  .presse-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .presse-table td {
    padding: 10px;
    border: 1px solid #ddd;
    vertical-align: middle;
  }
  
  .pdf-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
  
  .presse-table a {
    color: #1962e5;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .presse-table a:hover {
    text-decoration: underline;
  }
  